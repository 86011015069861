import { Injectable } from '@angular/core';
import { of as observableOf, Observable, of } from 'rxjs';
import { DocPassiviData } from '../data/doc-passivi';
import { tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

const URL_WS = 'https://fe.geniosoft.srl/soap/backend/invoice_in_findByUsername.php';


  const USERNAME = 'FEP.GENIOS19';

  // const USERNAME = 'PREMIUM00000000398';
  // const PASSWORD = 'AntCar99!';
@Injectable()

export class DocPassiviService extends DocPassiviData {

    constructor(private http: HttpClient) {
        super ();
    }

    getDocsPassivi(token: any, currentPage: number, startDate: string, endDate: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
        //   const piva = "03779110653";
        //  const piva = "00000000157";
        const piva = localStorage.getItem('subject');

        const op = "?size=100&page=" + currentPage + "&username=" + USERNAME + "&countrySender=IT&vatcodeReceiver=" + piva + "&token=" + token + "&startDate=" + startDate + "&endDate=" + endDate;

        const url = `${URL_WS}/${op}`;
        /*   return this.http.get<any[]>(url, { headers: reqHeader }).pipe(
               tap(_ => console.log(`fetched findByUsername piva=${piva}`)),
               catchError(this.handleError<any>(`findByUsername piva=${piva}`))
           );
           */
        return this.http.get<any[]>(url).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${piva}`)),
            catchError(this.handleError<any>(`findByUsername piva=${piva}`))
        );
    }

    getDocPassivi(token: any, filename: any, type: any): Observable<any> {
        const URL_WS = 'https://fe.geniosoft.srl/soap/backend/invoice_xml_in_getByFilename.php';

        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
        //   const piva = "03779110653";
        //  const piva = "00000000157";
        const piva = localStorage.getItem('subject');
        const op = "?filename=" + filename + "&token=" + token + "&type=" + type;
        const url = `${URL_WS}/${op}`;
        return this.http.get<any[]>(url, /* { headers: reqHeader }*/).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${piva}`)),
            catchError(this.handleError<any>(`findByUsername piva=${piva}`))
        );
    }

    viewDocPassivi(token: any, filename: any, type: any): Observable<any> {

        const URL_WS = 'https://fe.geniosoft.srl/soap/backend/viewDocPassiviSdi.php';

        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + token
        });
        //   const piva = "03779110653";
        //  const piva = "00000000157";
        const piva = localStorage.getItem('subject');
        const op = "?filename=" + filename + "&token=" + token + "&type=" + type;
        const url = `${URL_WS}/${op}`;
        return this.http.get<any[]>(url, /* { headers: reqHeader }*/).pipe(
            tap(_ => console.log(`fetched findByUsername piva=${piva}`)),
            catchError(this.handleError<any>(`findByUsername piva=${piva}`))
        );
    }

    downloadDocsPassivi(listaDocumentiPassivi: any, email: string, oggetto: string,
                        dataStart: string, dataEnd: string): Observable<any> {

        const params = new HttpParams()
            .set('email', email)
            .set('oggetto', oggetto)
            .set('dataStart', dataStart)
            .set('dataEnd', dataEnd);


        const URL_WS = 'https://fe.geniosoft.srl/soap/backend/downloadDocumentiPassivi.php';
      //  const op = "?email=" + email + "?oggetto=" + oggetto + "?dataStart=" + dataStart + "?dataEnd=" + dataEnd;
     //   const op = "?email=" + email;
        const op = '';
        const url = `${URL_WS}/${op}`;
        return this.http.post<any[]>(url, listaDocumentiPassivi, { params }).pipe(
            tap(_ => console.log(`fetched listaDocumentiPassivi piva=}`)),
            catchError(this.handleError<any>(`listaDocumentiPassivi piva=`))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            return of(result as T);
        };
    }
}
